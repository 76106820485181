import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/app-settings.service';

@Component({
  selector: 'app-symbol-nav',
  templateUrl: './symbol-nav.component.html',
  styleUrls: ['./symbol-nav.component.scss']
})
export class SymbolNavComponent implements OnInit {
  error: string = null;
  symbols: [];

  constructor(private settingsService: AppSettingsService) { }

  ngOnInit(): void {
    
    this.settingsService.getSymbols().subscribe(data => {
      
      this.symbols = data
      console.log(data);
    }, error => {
      this.error = error.message;
    });

  }

}
