import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  constructor(private http: HttpClient) { }

  getSymbols() {
    return this.http.get<any>('https://api.forexfuchs.de/settings/symbols');
  }

}
