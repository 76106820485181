import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }
  
  showProfileDropdown = false;

  navItems = [
    {id: 1, name: 'Dashboard', path: '/dashboard'},
    {id: 2, name: 'Calendar', path: '/dashboard'},
    {id: 3, name: 'Indices', path: '/dashboard'},
    {id: 4, name: 'Forex', path: '/dashboard'},
    {id: 5, name: 'Risk Reversals', path: '/riskreversals'},
    {id: 6, name: 'Misc', path: '/dashboard'}
  ]

  ngOnInit(): void {
    
  }

}
