      
      <div class="flex flex-col bg-gray-200">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                      Date
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                      Symbol
                    </th>
                    <th class="px-6 py-3 bg-gray-50 border-r-2 border-gray-400 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                      Price
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                      1 Week
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                      1 Month
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                        3 Month
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                      6 Month
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                        9 Month
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                        1 Year
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr *ngFor="let item of reversals">
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm leading-5 font-medium text-gray-900">
                            {{ item.date | date:'dd.MM.yyyy hh:mm' }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm leading-5 font-medium text-gray-900">
                            <span class="px-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-800">
                              {{ item.name }}
                            </span> 
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap border-r-2 border-gray-300">
                        <div class="flex content-between">
                            <div class="flex-1 text-sm leading-5 text-gray-900">{{ item.price }}</div>
                            <div class="flex">
                                <span class="px-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                    {{ item.price_change }} %
                                </span>   
                            </div>
                        </div>
                    </td>
                    <td class="p-0">
                      <app-risk-panel [price]="item.value_1w" [change]="item.change_1w"></app-risk-panel>
                    </td>
                    <td class="p-0">
                      <app-risk-panel [price]="item.value_1m" [change]="item.change_1m"></app-risk-panel>
                    </td>
                    <td class="p-0">
                      <app-risk-panel [price]="item.value_3m" [change]="item.change_3m"></app-risk-panel>
                    </td>
                    <td class="p-0">
                      <app-risk-panel [price]="item.value_6m" [change]="item.change_6m"></app-risk-panel>
                    </td>
                    <td class="p-0">
                      <app-risk-panel [price]="item.value_9m" [change]="item.change_9m"></app-risk-panel>    
                    </td>
                    <td class="p-0">
                      <app-risk-panel [price]="item.value_1y" [change]="item.change_1y"></app-risk-panel>  
                    </td>
                  </tr>
                  <!-- More rows... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

  
  
  
    