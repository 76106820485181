import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-risk-panel',
  templateUrl: './risk-panel.component.html',
  styleUrls: ['./risk-panel.component.scss']
})
export class RiskPanelComponent implements OnInit {
  @Input() price: any;
  @Input() change: any;  

  gain: boolean;

  constructor() { }

  ngOnInit(): void {
    this.gain = (this.change >= 0) ? true : false;
    this.price = parseFloat(this.price);
    this.change = parseFloat(this.change);
  }

  bgColor: string = '';
  highlightColor: string = '';

  public createColor(type) {
    let color: string = (this.gain) ? 'green' : 'red';
    let opacity: number = Math.round(this.change*10);
    if(opacity < 0){opacity = opacity*-1;}
    
    switch (type) {
      case 'bg':
        opacity = this.colorSchemes[opacity].bg;
        color = 'bg-'+color+'-'+opacity;
        break;
      case 'highlight':
        opacity = this.colorSchemes[opacity].highlight;
        color = 'bg-'+color+'-'+opacity;
        break;
      case 'text':
        opacity = this.colorSchemes[opacity].text;
        color = 'text-'+color+'-'+opacity;
        break;
      default:
        break;
    }    
    return color;
  }

  colorSchemes = [
    {highlight: 0, bg: 50, text: 0},
    {highlight: 200, bg: 100, text: 800},
    {highlight: 300, bg: 200, text: 700},
    {highlight: 400, bg: 300, text: 700},
    {highlight: 500, bg: 400, text: 700},
    {highlight: 600, bg: 500, text: 200},
    {highlight: 700, bg: 600, text: 200},
    {highlight: 800, bg: 700, text: 100},
    {highlight: 900, bg: 800, text: 100},
    {highlight: 1000, bg: 900, text: 100},
    {highlight: 1000, bg: 900, text: 100}
  ]

}
