import { Component, OnInit } from '@angular/core';
import { RiskreversalsServiceService } from './riskreversals-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-risk-reversals',
  templateUrl: './risk-reversals.component.html',
  styleUrls: ['./risk-reversals.component.scss']
})
export class RiskReversalsComponent implements OnInit {
  isFetching: boolean = false;
  error = null;
  reversals = [];

  constructor() { }

  ngOnInit(): void {
    
  }

  

  private randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  private getRandom(isChange:boolean) {
    let number:number;
    if(parseFloat(Math.random().toFixed(1)) >= 0.5) {
      number = 1;
    } else {
      number = -1;
    }
    let val:number = parseFloat(Math.random().toFixed(2)); 
    number = number*val;
    return number;
  }

  private createRecord(){
    this.reversals.push(
      {
        date: this.randomDate(new Date(2015, 0, 1), new Date(2020, 10, 10)),
        price: parseFloat(Math.random().toFixed(4))+1,
        priceChange: this.getRandom(true),
        ow: this.getRandom(false),
        owc: this.getRandom(true),
        om: this.getRandom(false),
        omc: this.getRandom(true),
        tm: this.getRandom(false),
        tmc: this.getRandom(true),
        sm: this.getRandom(false),
        smc: this.getRandom(true),
        nm: this.getRandom(false),
        nmc: this.getRandom(true),
        oy: this.getRandom(false),
        oyc: this.getRandom(true)
      }
    )
  }


}
