import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RiskReversalsComponent } from './risk-reversals/risk-reversals.component';
import { RiskreversalTableComponent } from './risk-reversals/riskreversal-table/riskreversal-table.component';


const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: 'Calendar', component: DashboardComponent },
  { path: 'indices', component: DashboardComponent },
  { path: 'forex', component: DashboardComponent },
  { path: 'riskreversals', component: RiskReversalsComponent, children: [
    {path: ':id', component: RiskreversalTableComponent}
  ]},
  { path: 'misc', component: DashboardComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
