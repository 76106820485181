<div class="px-6 py-4 text-gray-500 text-sm" [ngClass]="createColor('bg')">
    <div class="flex content-between text-center" [ngClass]="createColor('text')">
        <div class="flex-none">{{ price }}</div>
        <div class="flex-grow"></div>
        <div class="flex">
            <span class="px-4 inline-flex text-sm leading-5 font-semibold rounded-full" [ngClass]="createColor('highlight')">
                <span *ngIf="change >= 0">+</span>{{ change }}
            </span>  
        </div>
    </div>
</div>

