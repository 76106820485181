<div class="flex">
  <div class="flex-initial mr-8">
    <app-symbol-nav></app-symbol-nav>
  </div>
  <div class="flex-1">
    <router-outlet></router-outlet>
  </div>
</div>




  