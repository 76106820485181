import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RiskreversalsServiceService } from '../riskreversals-service.service';

@Component({
  selector: 'app-riskreversal-table',
  templateUrl: './riskreversal-table.component.html',
  styleUrls: ['./riskreversal-table.component.scss']
})
export class RiskreversalTableComponent implements OnInit {

  isFetching: boolean = false;
  error = null;
  reversals = [];

  constructor(private riskReversalService: RiskreversalsServiceService, 
    private route: ActivatedRoute,
    private router: Router) { 
      this.route.paramMap.subscribe(params => {
        this.ngOnInit();
      })
    }

  ngOnInit(): void {

    let symboldId = this.route.snapshot.params.id;

    this.isFetching = true;
    this.riskReversalService.getData(symboldId).subscribe(data => {
      this.isFetching = false;
      this.reversals = data
      console.log(data);
    }, error => {
      this.error = error.message;
    });

  }

}
