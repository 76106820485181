<div class="h-screen">
  <app-navbar></app-navbar>

  <header class="bg-white shadow">
    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold leading-tight text-gray-900">25D - Risk Reversals</h1>
    </div>
  </header>
  <main class="h-full bg-gray-100">
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
