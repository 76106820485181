import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RiskreversalsServiceService {

  constructor(private http: HttpClient) { }

  getData(pair) {
    return this.http.get<any>('https://api.forexfuchs.de/riskreversals/'+pair);
  }

}
