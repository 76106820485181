<div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <tbody class="bg-white divide-y divide-gray-200">
              <tr *ngFor="let symbol of symbols" class="hover:bg-gray-300 cursor-pointer outline-none" [routerLink]="[symbol.id]" routerLinkActive="bg-gray-200" >
                <td class="px-6 py-2 whitespace-no-wrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-6 w-6">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 486.404 486.404" style="enable-background:new 0 0 486.404 486.404;" xml:space="preserve">
                   <path style="fill:#E2A63B;" d="M486.404,317.919v55.38c0,18.808-50.155,34.482-111.804,34.482s-110.759-15.151-110.759-33.959
                       v-6.269l-1.045-43.886h0.522c8.359,16.196,54.335,28.735,109.714,28.735s112.327-15.673,112.327-34.482H486.404z"/>
                   <path style="fill:#F4B844;" d="M371.465,112.074v54.857h-1.045c0,19.331-51.722,34.482-112.849,34.482
                       c-23.51,0.522-47.543-2.09-70.531-7.837l-1.045,1.045c-11.494-9.404-24.555-16.718-38.661-21.42v-1.045l-1.567-57.992
                       c3.657,17.763,52.245,31.869,111.282,31.869s113.371-15.151,113.371-33.959H371.465z"/>
                   <path style="fill:#FEDB41;" d="M371.465,113.119L371.465,113.119c0,18.808-51.722,33.959-113.371,33.959
                       s-108.147-14.106-111.804-31.869c0-0.522-0.522-1.567-0.522-2.09c0-18.808,50.155-34.482,112.327-34.482
                       C320.265,78.115,371.465,93.788,371.465,113.119z"/>
                   <path style="fill:#F4B844;" d="M263.318,323.666L263.318,323.666l-0.522-11.494l-1.045-47.02
                       c3.657,17.763,52.245,31.869,111.282,31.869s111.282-15.151,112.849-33.437h0.522v54.335h-1.045
                       c0,18.808-50.678,34.482-112.327,34.482C311.384,352.4,271.678,339.862,263.318,323.666z"/>
                   <path style="fill:#E2A63B;" d="M188.608,358.67c14.106-13.061,25.078-29.78,30.824-48.065h2.612
                       c11.494,1.045,22.988,1.567,35.527,1.567h5.224v11.494l1.045,43.886h-5.747c-22.988,0.522-45.453-2.09-67.918-7.314L188.608,358.67z
                       "/>
                   <path style="fill:#F4B844;" d="M263.318,260.972v2.612l1.045,48.588h-5.225c-13.061,0-25.078-0.522-36.571-1.567h-2.612
                       c3.657-10.971,5.224-22.465,5.224-33.959c0-7.837-1.045-15.151-2.612-22.465h2.612c10.971,1.045,21.943,1.567,33.959,1.567
                       l4.702,2.612C263.318,259.404,263.318,260.449,263.318,260.972z"/>
                   <path style="fill:#E2A63B;" d="M187.041,193.576c22.988,5.747,47.02,8.359,70.531,7.837c61.126,0,112.849-15.151,112.849-34.482
                       h1.045v61.649c-56.947,0-103.445,13.584-109.192,30.824l-4.702-2.612c-11.494,0-22.465-0.522-32.914-1.567h-2.612
                       c-4.702-24.033-17.241-45.453-36.049-61.127L187.041,193.576z"/>
                   <g>
                       <path style="fill:#FEDB41;" d="M223.09,255.225L223.09,255.225c-5.224-24.033-18.286-45.975-37.094-62.171
                           c-11.494-10.449-25.078-17.763-39.706-21.943c-10.449-3.657-21.943-5.224-32.914-5.224C51.204,165.363,0.526,214.996,0.004,277.167
                           c-0.522,62.171,49.11,112.849,111.282,113.371c28.735,0,55.902-10.449,77.322-29.78c14.629-13.584,25.6-30.824,31.347-49.633
                           c3.657-10.971,5.224-21.943,5.224-33.437C225.18,270.376,223.09,262.539,223.09,255.225z"/>
                       <path style="fill:#FEDB41;" d="M485.882,262.539v1.045c-1.567,18.286-52.245,33.437-112.849,33.437s-107.625-14.106-111.282-31.869
                           v-2.612c0-1.045,0-2.09,0.522-3.135c5.747-17.241,52.245-30.824,109.192-30.824h1.567
                           C434.682,228.58,485.882,243.731,485.882,262.539z"/>
                   </g>
                   <path style="fill:#E2A63B;" d="M113.376,264.106c-9.404,0-17.241-7.837-17.241-17.241c0-9.404,7.837-17.241,17.241-17.241
                       s17.241,7.837,17.241,17.241c0,5.747,4.702,10.449,10.449,10.449c5.747,0,10.449-4.702,10.449-10.449
                       c0-16.718-11.494-31.869-27.69-36.571v-4.18c0-5.747-4.702-10.449-10.449-10.449s-10.449,4.702-10.449,10.449v4.18
                       c-16.718,4.18-28.212,19.331-27.69,36.571c0,20.898,17.241,38.139,38.139,38.139c9.404,0,17.241,7.837,17.241,17.241
                       c0,9.404-7.837,17.241-17.241,17.241l0,0c-9.404,0-17.241-7.837-17.241-17.241c0-5.747-4.702-10.449-10.449-10.449
                       s-10.449,4.702-10.449,10.449c0,17.241,11.494,31.869,27.69,36.571v8.359c0,5.747,4.702,10.449,10.449,10.449
                       s10.449-4.702,10.449-10.449v-8.359c20.375-5.747,31.869-27.167,25.6-47.543C144.723,275.6,130.094,264.106,113.376,264.106z"/>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   </svg>
                    </div>
                    <div class="ml-4">
                      <div class="text-xs leading-5 font-medium text-gray-900">
                        {{ symbol.name }}
                      </div>
                      <div class="text-xs leading-5 text-gray-500">
                        {{ symbol.description }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                  <a href="#" class="text-indigo-600 hover:text-indigo-900">Show</a>
                </td>
              </tr>
  
              <!-- More rows... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  